// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import ColorRender from 'viewmodel/ColorRenderViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const DivVisibleWhen = VisibleWhen('div');
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/oPINEngineeringModule/oPINEngineeringService', () => import('js/oPINEngineeringModule/oPINEngineeringService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "LastPublishedDate": {
            "displayName": "Last Published Date",
            "dbValue": "{{props.subPanelContext.selection[0]._data.LastPublishedDate}}",
            "dispValue": "{{props.subPanelContext.selection[0]._data.LastPublishedDate}}"
        },
        "JustificationMode": {
            "displayName": "Stop justification mode",
            "dbValue": "{{props.subPanelContext.selection[0]._data.JustificationMode}}",
            "dispValue": "{{ctx.readdableStopJustificationItems[props.subPanelContext.selection[0]._data.JustificationMode]}}"
        },
        "MicrostopColor": {
            "initialValues": {
                "displayName": "MicrostopColor",
                "dbValue": "",
                "dispValue": ""
            },
            "meta": {}
        }
    },
    "actions": {
        "getSelectedObject": {
            "actionType": "JSFunction",
            "method": "returnData",
            "inputData": {
                "data": "{{data}}",
                "fields": "{{fields}}"
            },
            "deps": "js/oPINEngineeringModule/oPINEngineeringService",
            "outputData": {
                "LastPublishedDate": "LastPublishedDate",
                "JustificationMode": "JustificationMode",
                "MicrostopColor": "MicrostopColor"
            }
        },
        "setFromCtx": {
            "actionType": "JSFunction",
            "method": "returnData",
            "inputData": {
                "data": "{{data}}",
                "fields": "{{fields}}"
            },
            "deps": "js/oPINEngineeringModule/oPINEngineeringService",
            "outputData": {
                "LastPublishedDate": "LastPublishedDate",
                "JustificationMode": "JustificationMode",
                "MicrostopColor": "MicrostopColor"
            }
        }
    },
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "getSelectedObject",
                "observers": [
                    "props.subPanelContext.selection.uid",
                    "props.subPanelContext.selection[0].uid",
                    "props.subPanelContext.selection.length"
                ]
            }
        ]
    },
    "onEvent": [
        {
            "eventId": "appCtx.*",
            "condition": "name === 'currentEngPrj' && ctx.currentEngPrj !== null && ctx.currentEngPrj !== undefined",
            "action": "setFromCtx"
        }
    ],
    "i18n": {
        "TRUE": [
            "commonUtilsMessages"
        ],
        "FALSE": [
            "commonUtilsMessages"
        ],
        "Name": [
            "oPINEngineeringMessages"
        ],
        "displayName": [
            "oPINEngineeringMessages"
        ],
        "Description": [
            "oPINEngineeringMessages"
        ],
        "Language": [
            "oPINEngineeringMessages"
        ],
        "lastPublishedDate": [
            "oPINEngineeringMessages"
        ],
        "stopJustificationMode": [
            "oPINEngineeringMessages"
        ],
        "defaultMicrostopColor": [
            "oPINEngineeringMessages"
        ],
        "mandatoryCommentOnMetricChanges": [
            "oPINEngineeringMessages"
        ],
        "mandatoryCommentOnMetricInsertions": [
            "oPINEngineeringMessages"
        ],
        "mandatoryCommentOnStateChanges": [
            "oPINEngineeringMessages"
        ],
        "mandatoryCommentOnStateJustifications": [
            "oPINEngineeringMessages"
        ],
        "manageQualityCode": [
            "oPINEngineeringMessages"
        ],
        "discardDataWithBadQuality": [
            "oPINEngineeringMessages"
        ],
        "discardDataWithUncertainQuality": [
            "oPINEngineeringMessages"
        ],
        "qualityCodes": [
            "oPINEngineeringMessages"
        ],
        "useAutomaticStateJustification": [
            "oPINEngineeringMessages"
        ],
        "OPC_UA": [
            "oPINEngineeringMessages"
        ],
        "OPC_Classic": [
            "oPINEngineeringMessages"
        ],
        "overview": [
            "oPINEngineeringMessages"
        ],
        "logJustificationDetails": [
            "oPINEngineeringMessages"
        ]
    },
    "_viewModelId": "oPINEngineeringProjectsDetails",
    "_uniqueViewModelId": "oPINEngineeringProjectsDetails",
    "ctx": {
        "readdableStopJustificationItems": {
            "type": "object"
        },
        "currentEngPrj": {
            "type": "object"
        }
    }
};

    /*
    <aw-panel-body>
    <aw-command-panel-section width="12" caption="i18n.overview" height="fill" collapsed="disabled">
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Name}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Name}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.displayName}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.DisplayName}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Description}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.Description}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.Language}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{props.subPanelContext.selection[0]._data.LanguageString}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.lastPublishedDate}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{data.LastPublishedDate.dispValue}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.stopJustificationMode}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val">
            <div class="aw-widgets-propertyNonEditValue">{{data.JustificationMode.dispValue}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.defaultMicrostopColor}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer sw-property-val" style="max-width:200px;margin-left:-3px;">
            <color-render prop="data.MicrostopColor"></color-render>
        </div>
    </div>    
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.mandatoryCommentOnMetricChanges}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.CommentMetricMod">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.CommentMetricMod">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.mandatoryCommentOnMetricInsertions}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.CommentMetricIns">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.CommentMetricIns">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.mandatoryCommentOnStateChanges}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.CommentStateMod">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.CommentStateMod">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.mandatoryCommentOnStateJustifications}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.CommentStateJust">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.CommentStateJust">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.manageQualityCode}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.ManageQualityCode">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.ManageQualityCode">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.discardDataWithBadQuality}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.DiscardDataWithBadQuality">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.DiscardDataWithBadQuality">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.discardDataWithUncertainQuality}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.DiscardDataWithUncertainQuality">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.DiscardDataWithUncertainQuality">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.qualityCodes}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.QualityType">{{i18n.OPC_UA}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.QualityType">{{i18n.OPC_Classic}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.useAutomaticStateJustification}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
            <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.UseAutomaticStateJustification">{{i18n.TRUE}}</div>
            <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.UseAutomaticStateJustification">{{i18n.FALSE}}</div>
        </div>
    </div>
    <aw-break></aw-break>
    <div class="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
        <div class="aw-widgets-propertyLabelTop sw-property-name" style="width:250px;max-width:300px;">{{i18n.logJustificationDetails}}</div>
        <div class="aw-widgets-propertyLabelTopValueContainer">
          <div class="aw-widgets-propertyNonEditValue" visible-when="props.subPanelContext.selection[0]._data.LogJustDetails">{{i18n.TRUE}}</div>
          <div class="aw-widgets-propertyNonEditValue" visible-when="!props.subPanelContext.selection[0]._data.LogJustDetails">{{i18n.FALSE}}</div>
        </div>
      </div>
</aw-command-panel-section>
</aw-panel-body>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanelBody>
        <AwCommandPanelSection width="12" caption={i18n.overview} height="fill" collapsed="disabled">
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.displayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.DisplayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.Description" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.Language" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "props.subPanelContext.selection[0]._data.LanguageString" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.lastPublishedDate" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "data.LastPublishedDate.dispValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.stopJustificationMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val">
                    <div className="aw-widgets-propertyNonEditValue">
                        {AwParseService.instance( "data.JustificationMode.dispValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </div>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.defaultMicrostopColor" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer sw-property-val" style={{maxWidth: '200px', marginLeft: '-3px'}}>
                    <ColorRender prop={AwParseService.instance( "fields.MicrostopColor" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </ColorRender>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.mandatoryCommentOnMetricChanges" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.CommentMetricMod" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.CommentMetricMod" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.mandatoryCommentOnMetricInsertions" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.CommentMetricIns" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.CommentMetricIns" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.mandatoryCommentOnStateChanges" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.CommentStateMod" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.CommentStateMod" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.mandatoryCommentOnStateJustifications" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.CommentStateJust" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.CommentStateJust" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.manageQualityCode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.ManageQualityCode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.ManageQualityCode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.discardDataWithBadQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.DiscardDataWithBadQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.DiscardDataWithBadQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.discardDataWithUncertainQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.DiscardDataWithUncertainQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.DiscardDataWithUncertainQuality" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.qualityCodes" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.QualityType" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.OPC_UA" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.QualityType" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.OPC_Classic" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.useAutomaticStateJustification" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.UseAutomaticStateJustification" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.UseAutomaticStateJustification" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
            <AwBreak>
            </AwBreak>
            <div className="aw-widgets-propertyContainer aw-layout-flexRow sw-property sw-component sw-row sw-readOnly">
                <div className="aw-widgets-propertyLabelTop sw-property-name" style={{width: '250px', maxWidth: '300px'}}>
                    {AwParseService.instance( "i18n.logJustificationDetails" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
                <div className="aw-widgets-propertyLabelTopValueContainer">
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "props.subPanelContext.selection[0]._data.LogJustDetails" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.TRUE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                    <DivVisibleWhen className="aw-widgets-propertyNonEditValue" visibleWhen={AwParseService.instance( "!props.subPanelContext.selection[0]._data.LogJustDetails" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {AwParseService.instance( "i18n.FALSE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    </DivVisibleWhen>
                </div>
            </div>
        </AwCommandPanelSection>
    </AwPanelBody>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;